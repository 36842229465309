import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import { Combobox, ComboboxButton, ComboboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { type ReactNode, useCallback, useMemo, useState } from 'react';

import { Button } from 'app/components/Button';
import { compact, uniq } from 'lodash';
import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionGroupPrimitive } from '../../primitives/DropdownOptionGroupPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';
import { PlaceholderPrimitive } from '../../primitives/PlaceholderPrimitive';

type OptionType<T> = DropdownOptionPrimitiveProps<T>['option'];

export type DropdownMultiSelectProps<T extends React.Key = string> = {
  label?: string;
  options: OptionType<T>[];
  includeSelectAll?: boolean;
  loading?: boolean;
  value: T[] | null;
  disabled?: boolean;
  backgroundColor?: string;
  onChange: (val: T[]) => void;
  className?: string;
  height?: string;
  placeholder?: string;
  maxDisplaySize?: number;
  autocomplete?: boolean;
  optionsDisplayType?: 'relative' | 'absolute';
  showArrow?: boolean;
  actions?: ReactNode;
  groups?: {
    label: string;
    optionKeys: OptionType<T>['value'][];
  }[];
  align?: 'left' | 'right';
};
export const MultiSelect = <T extends React.Key = string>({
  label,
  options,
  includeSelectAll,
  disabled,
  backgroundColor,
  className = '',
  loading,
  placeholder,
  value: values,
  height = 'h-9',
  maxDisplaySize = 100,
  showArrow = true,
  // Should the options be displayed in an absolute or relative container
  optionsDisplayType = 'absolute',
  onChange,
  autocomplete,
  actions,
  groups,
  align = 'left',
}: DropdownMultiSelectProps<T>) => {
  const t = useTranslations();
  const [showOptions, setShowOptions] = useState(false);
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');
  const [hoveredOption, setHoveredOption] = useState<OptionType<T> | null>(
    null,
  );
  const [selectedOptions, setSelectedOptions] = useState<
    OptionType<T>['value'][]
  >(values || []);

  let bgColor = 'bg-white';

  if (backgroundColor) {
    bgColor = backgroundColor;
  }
  if (!placeholder) {
    placeholder = t('Dropdown.selectOption');
  }
  const handleChange = useCallback(
    (newPickedOptions: OptionType<T>[]) => {
      if (!newPickedOptions) {
        return;
      }
      let newOptionValues = newPickedOptions
        .filter(Boolean)
        .map((opt) => opt.value);

      console.log('newOptionValues', newOptionValues);

      if (newOptionValues.includes('$all$' as T)) {
        if (selectedOptions.length === options.length) {
          // deselect all
          newOptionValues = [];
        } else {
          // select all
          newOptionValues = options.map((opt) => opt.value);
        }
      }

      setSelectedOptions(newOptionValues);
    },
    [options, selectedOptions],
  );
  const handleSave = useCallback(() => {
    setShowOptions(false);
    setAutocompleteSearchTerm('');
    onChange(selectedOptions);
  }, [selectedOptions, onChange]);
  const handleCancel = useCallback(() => {
    setShowOptions(false);
    setAutocompleteSearchTerm('');
  }, []);

  const optionsMap = useMemo(() => {
    const map = new Map<T, OptionType<T>>();
    for (const option of options) {
      map.set(option.value, option);
    }
    return map;
  }, [options]);

  const groupedOptionsMap = useMemo(() => {
    // Build a map to know which option is grouped
    const map = new Map<T, true>();
    for (const group of groups || []) {
      for (const option of group.optionKeys) {
        map.set(option, true);
      }
    }
    return map;
  }, [groups]);

  const { filteredOptions, filteredResultsLength } = useMemo(() => {
    if (!autocomplete) {
      return {
        filteredOptions: options,
        filteredResultsLength: options.length,
      };
    }

    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(autocompleteSearchTerm.toLowerCase()),
    );

    const filteredResultsLength = filteredOptions.length;

    if (filteredOptions.length > maxDisplaySize) {
      filteredOptions = filteredOptions.slice(0, maxDisplaySize);
    }

    return { filteredOptions, filteredResultsLength };
  }, [options, autocomplete, autocompleteSearchTerm, maxDisplaySize]);

  const valueOptions = options.filter((anOption) =>
    values?.includes(anOption.value),
  );
  const printedSelectedValuesLabels = valueOptions
    .map((opt) => opt.label)
    .join(', ');
  const isDisabled = disabled || options.length < 1;

  if (loading) {
    return <Skeleton height={height} width="min-w-[90px] w-full" />;
  }

  if (options.length === 0) {
    return null;
  }

  const containerBoxClass = 'bg-white p-2 focus:outline-hidden w-full';

  return (
    <Combobox
      disabled={isDisabled}
      multiple
      value={
        selectedOptions?.map((val) =>
          options.find((opt) => opt.value === val),
        ) || []
      }
      onChange={handleChange}
    >
      {() => (
        <div
          className={classNames(
            'relative border-carbon-100 w-fit transition-all',
            height,
            className,
          )}
        >
          {(!autocomplete || (autocomplete && !showOptions)) && (
            <ComboboxButton
              className={classNames(
                'rounded-md border-[1.5px] box-border border-carbon-100 hover:border-gray-300 items-center px-2 focus:outline-hidden h-full w-full justify-between flex flex-row',
                bgColor,
              )}
              onClick={() => setShowOptions(!showOptions)}
            >
              <DropdownButtonPrimitive
                showArrow={showArrow}
                disabled={isDisabled}
                prefix={label}
              >
                {!values?.length && <PlaceholderPrimitive text={placeholder} />}
                {(values?.length ?? 0) >= 1 && (
                  <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-68">
                    {printedSelectedValuesLabels}
                  </p>
                )}
              </DropdownButtonPrimitive>
            </ComboboxButton>
          )}

          {showOptions && (
            <>
              <ComboboxOptions
                static
                className={classNames(
                  'rounded-md shadow-lg ring-1 ring-black/5 z-50 flex flex-row mb-20 min-w-full origin-top-right overflow-hidden gap-2 ',
                  align === 'left' ? 'left-0' : 'right-0',
                  optionsDisplayType === 'absolute'
                    ? 'absolute'
                    : 'relative top-[-0.5rem] left-[-0.5rem]',
                )}
              >
                <div className={containerBoxClass}>
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      placeholder={placeholder}
                      selectedText={printedSelectedValuesLabels}
                      label={label}
                      onChange={setAutocompleteSearchTerm}
                      value={autocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}
                  {autocomplete &&
                    filteredOptions.length > 0 &&
                    filteredOptions.length !== filteredResultsLength && (
                      <div className="p-1 text-xs text-carbon-500 font-medium border-b border-carbon-100 flex pb-2">
                        {t('Dropdown.displayingXOptions', {
                          count: filteredOptions.length,
                          total: filteredResultsLength,
                        })}
                      </div>
                    )}
                  <DropdownOptionsScrollerPrimitive>
                    {includeSelectAll && !autocompleteSearchTerm ? (
                      <DropdownOptionPrimitive
                        key="$all$"
                        option={{
                          label: t('Dropdown.Multiselect.selectAll'),
                          value: '$all$',
                        }}
                        selected={selectedOptions?.length === options.length}
                      >
                        {t('Dropdown.Multiselect.selectAll')}
                      </DropdownOptionPrimitive>
                    ) : null}

                    {actions}

                    {groups?.map((group, index) => {
                      const groupOptions = compact(
                        // Handling duplicate keys & missing keys
                        uniq(group.optionKeys).map((key) =>
                          optionsMap.get(key),
                        ),
                      ).filter((option) =>
                        filteredOptions.some(
                          (fo) => fo.value === option?.value,
                        ),
                      );

                      if (groupOptions.length === 0) {
                        return null;
                      }

                      return (
                        <DropdownOptionGroupPrimitive
                          key={group.label}
                          index={index}
                          label={group.label}
                        >
                          {groupOptions.map((option) => (
                            <DropdownOptionPrimitive
                              key={`${group.label}-${option.value}`}
                              option={option}
                              onHover={(val) => setHoveredOption(val)}
                              selected={selectedOptions?.includes(option.value)}
                            >
                              {option.label}
                            </DropdownOptionPrimitive>
                          ))}
                        </DropdownOptionGroupPrimitive>
                      );
                    })}
                    {
                      // Show group-less options
                      filteredOptions
                        .filter(
                          (option) => !groupedOptionsMap.has(option.value),
                        )
                        .map((option) => (
                          <DropdownOptionPrimitive
                            key={option.value}
                            option={option}
                            onHover={(val) => setHoveredOption(val)}
                            selected={selectedOptions?.includes(option.value)}
                          >
                            {option.label}
                          </DropdownOptionPrimitive>
                        ))
                    }
                  </DropdownOptionsScrollerPrimitive>
                  <div className="flex flex-row gap-2 justify-end pt-2">
                    <Button.Default variant="secondary" onClick={handleCancel}>
                      {t('Dropdown.Multiselect.cancel')}
                    </Button.Default>
                    <Button.Default variant="primary" onClick={handleSave}>
                      {t('Dropdown.Multiselect.confirm')}
                    </Button.Default>
                  </div>
                </div>
              </ComboboxOptions>
              {hoveredOption && (
                <div
                  className={classNames(
                    containerBoxClass,
                    'absolute left-full ml-[-4px] top-0 text-gray-900 text-sm w-64 z-50 whitespace-normal p-4 rounded-md  shadow-lg ring-1 ring-black/5 ',
                  )}
                >
                  {hoveredOption.hoverContent}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Combobox>
  );
};
